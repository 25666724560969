<template>
  <div class="row">
    <div class="col-12 mb-3">
      <!--PAYMENTS-->
      <CardPlain :disabled="disabled || isLoadingValue">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="letter_spacing green-text-dark bold text-center">
              PAYMENT DETAILS
            </div>
            <StripeSection
              @stripeContinue="verifyDetails"
              @recurringComplete="recurringComplete"
              :isUkPayment="isUkPayment"
              v-model:isLoading="isLoadingValue"
              :turnstileReturn="turnstileReturn"
              :isRecurringPayment="isRecurringPayment"
            />
          </div>
        </div>
      </CardPlain>
      <!--END PAYMENTS-->
    </div>
    <transition name="fade">
      <SetupRecurringDonationsUK v-if="isRecurring" @close="isRecurring = false" @closeAll="closeAll" :hasCardDetails="true" :isRecurringReady="isRecurringReady" @checkRecurringCheckout="verifyRecurring" @checkoutRecurring="checkoutRecurring"/>
    </transition>
    <transition name="fade">
      <TurnstilePopup v-if="isTurnstile" @close="closeTurnstile" @verify="verifyTurnstile"/>
    </transition>
  </div>
</template>

<script>

import { mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    TurnstilePopup: defineAsyncComponent(() => import('@/views/TurnstilePopup.vue')),
    CardPlain: defineAsyncComponent(() => import('@/components/CardPlain.vue')),
    SetupRecurringDonationsUK: defineAsyncComponent(() => import('@/views/recurringDonations/uk/SetupRecurringDonations.vue')),
    StripeSection: defineAsyncComponent(() => import('@/views/stripe/StripeSection.vue'))
  },
  name: 'Checkout Payment UK',
  props: ['anonymousDetailsInvalid', 'transactionResponse', 'paymentType', 'disabled', 'isLoading'],
  emits: ['verifyAnonDetails', 'getCurrentTransactionReference', 'update:isLoading'],
  data () {
    return {
      isTurnstile: false,
      isRecurring: false,
      isRecurringReady: false,
      isRecurringPayment: null,
      turnstileReturn: null,
      isUkPayment: false
    }
  },
  watch: {
    async transactionResponse () {
      if (this.transactionResponse) {
        await this.actualCheckout()
      }
    }
  },
  computed: {
    isLoadingValue: {
      get () {
        return this.isLoading
      },
      set (value) {
        this.$emit('update:isLoading', value)
      }
    }
  },
  methods: {
    ...mapActions(['checkout', 'clearCart', 'setDonateAs']),
    async verifyDetails () {
      await this.$emit('verifyAnonDetails')
      if (this.paymentType === 'recurring') {
        this.isRecurring = true
      } else {
        await this.checkTurnstile()
      }
    },
    async verifyRecurring () {
      await this.checkTurnstile()
    },
    recurringComplete () {
      this.$router.push(`/recurringpaymentcompleteUk/${this.isRecurringPayment.subscriptionReference}/${this.isRecurringPayment.recurringDonationID}`)
    },
    closeTurnstile () {
      this.isLoadingValue = false
      this.turnstileReturn = null
      this.isTurnstile = false
    },
    verifyTurnstile (val) {
      this.turnstileReturn = val
      this.isTurnstile = false
      if (this.turnstileReturn) {
        this.goCheckout()
      }
    },
    checkTurnstile () {
      if (this.anonymousDetailsInvalid) {
        return false
      }
      this.isTurnstile = true
    },
    async goCheckout () {
      if (this.anonymousDetailsInvalid) {
        return false
      }
      if (this.isRecurring) {
        this.isRecurringReady = true
      } else {
        await this.$emit('getCurrentTransactionReference')
      }
    },
    async checkoutRecurring (val) {
      if (val.data) {
        this.isRecurringPayment = val.data
      }
    },
    async actualCheckout () {
      const returnDetails = await this.checkout(this.turnstileReturn)
      if (returnDetails) {
        this.isUkPayment = true
      }
      this.closeAll()
    },
    async closeAll () {
      await this.clearCart()
      this.isRecurring = false
      this.turnstileReturn = null
      await this.setDonateAs(null)
    },
    cancelPayment () {
      this.isUkPayment = false
    }
  }
}
</script>
